import React, { useState } from 'react';

const defaultState = {
  hideNavigation: false,
};

type ContextStateType = typeof defaultState;

export type ContextType = {
  pageCustomization: ContextStateType;
  setPageCustomization: React.Dispatch<React.SetStateAction<ContextStateType>>;
};

export const PageCustomizationContext = React.createContext<ContextType>({
  pageCustomization: defaultState,
  setPageCustomization: null,
});

export const PageCustomizationProvider: React.FC = ({ children }) => {
  const [pageCustomization, setPageCustomization] = useState(defaultState);

  return (
    <PageCustomizationContext.Provider
      value={{ pageCustomization, setPageCustomization }}
    >
      {children}
    </PageCustomizationContext.Provider>
  );
};
