module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/keytrade-localization/gatsby-browser.js'),
      options: {"plugins":[],"defaultLocaleFile":"./src/intl/en.json","defaultLanguage":"en","languages":"en,nl,fr"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.keytradebank.be"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Keytrade Bank","short_name":"Keytrade","start_url":"/","background_color":"#03B3D9","theme_color":"#03B3D9","display":"standalone","icon":"src/images/favicon.svg","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"86e52deaaf545f313a65e4a2811a2f80"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
