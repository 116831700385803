import React, { useState, useEffect } from 'react';

export type CookieConsentContextType = {
  consentActiveGroups: string[];
  hasConsentFor: (group: string) => boolean;
};

export const CookieConsentContext =
  React.createContext<CookieConsentContextType>({
    consentActiveGroups: [],
    hasConsentFor: null,
  });

const getActiveGroups = () => {
  let activeGroups = '';

  if (typeof window !== 'undefined') {
    activeGroups = window['OnetrustActiveGroups'] || '';
  }

  return activeGroups.split(',').filter((group) => !!group);
};

export const CookieConsentProvider: React.FC = ({ children }) => {
  const [consentActiveGroups, setConsentActiveGroups] = useState(
    getActiveGroups(),
  );

  useEffect(() => {
    const handleConsentChange = () => {
      setConsentActiveGroups(getActiveGroups());
    };

    let retryTimeout: NodeJS.Timeout;
    let retryCount = 0;
    const maxRetries = 20;

    const trySettingConsentCallback = () => {
      if (window['OneTrust']?.OnConsentChanged) {
        window['OneTrust'].OnConsentChanged(handleConsentChange);
        handleConsentChange();
      } else if (retryCount < maxRetries) {
        retryCount++;
        retryTimeout = setTimeout(trySettingConsentCallback, 125);
      }
    };

    trySettingConsentCallback();

    return () => {
      if (retryTimeout) {
        clearTimeout(retryTimeout);
      }
    };
  }, []);

  const hasConsentFor = (group: string) => {
    return consentActiveGroups.includes(group);
  };

  return (
    <CookieConsentContext.Provider
      value={{ consentActiveGroups, hasConsentFor }}
    >
      {children}
    </CookieConsentContext.Provider>
  );
};
