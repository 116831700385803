"use strict";

exports.__esModule = true;
exports.urlPrefixPath = exports.getLanguages = exports.defaultLang = void 0;
/* global KEYTRADE_LOCALIZATION_DEFAULT_LANGUAGE */
/* global KEYTRADE_LOCALIZATION_LANGUAGES */
/* global KEYTRADE_LOCALIZATION_URL_PREFIX_PATH */
var defaultLang = exports.defaultLang = KEYTRADE_LOCALIZATION_DEFAULT_LANGUAGE || process.env.DEFAULT_LANGUAGE || 'en';
var getLanguages = exports.getLanguages = function getLanguages() {
  return (KEYTRADE_LOCALIZATION_LANGUAGES || process.env.LANGUAGES || 'en,nl,fr').split(',');
};
var urlPrefixPath = exports.urlPrefixPath = KEYTRADE_LOCALIZATION_URL_PREFIX_PATH || process.env.URL_PREFIX_PATH;