import React from 'react';

import AppProviders from '@/components/AppProviders';
import { trackPage } from './src/utils/matomoHelpers';

export const wrapRootElement = ({ element }) => (
  <AppProviders>{element}</AppProviders>
);

export const onClientEntry = () => {
  setTimeout(() => {
    trackPage();
  }, 3000);
};

export const onRouteUpdate = () => {
  trackPage();
};
