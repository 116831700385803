import React, { createContext, useState } from 'react';

type ContextType = {
  isSearching: boolean;
  setIsSearching: React.Dispatch<any>;
};

const defaultValue = false;
const IsSearchingContext = createContext<ContextType>({
  isSearching: defaultValue,
  setIsSearching: () => undefined,
});
const IsSearchingProvider: React.FC = ({ children }) => {
  const [isSearching, setIsSearching] = useState(defaultValue);

  return (
    <IsSearchingContext.Provider value={{ isSearching, setIsSearching }}>
      {children}
    </IsSearchingContext.Provider>
  );
};

export { IsSearchingContext, IsSearchingProvider };
