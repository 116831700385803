import React, { ReactNode } from 'react';

import { CookieConsentProvider } from '@/context/CookieConsentContext';
import { PageCustomizationProvider } from '@/context/PageCustomizationContext';
import { IsSearchingProvider } from '@/context/IsSearchingContext';

type ChildrenOnly = {
  children: ReactNode;
};

const AppProviders = ({ children }: ChildrenOnly): ReactNode => (
  <PageCustomizationProvider>
    <CookieConsentProvider>
      <IsSearchingProvider>{children}</IsSearchingProvider>
    </CookieConsentProvider>
  </PageCustomizationProvider>
);

export default AppProviders;
