import matomo from '@keytrade/matomo';

export const trackPage = () => {
  const siteIDS = process.env.GATSBY_MATOMO_ANALYTICS_SITEID;

  if (siteIDS) {
    if (typeof window !== 'undefined') {
      window['matomoTrackPage'] = matomo.trackPage;
      window['matomoSitesIDS'] = siteIDS.split(',');
    }

    matomo.trackPage(siteIDS.split(','));
  }
};

/**
 * Track an event and push it to Matomo.
 *
 * @param {string} category Category set for this event
 * @param {string} action Action set for this event
 * @param {string} name Optional name set for this event (should be name OR value)
 * @param {string} value Optional value set for this event (should be name OR value)
 * @param {function} callback Optional callback set for this event to be called after matomo request is fulfilled
 */
export const trackEvent = (category, action, name, value, callback) => {
  const siteIDS = process.env.GATSBY_MATOMO_ANALYTICS_SITEID;
  if (siteIDS) {
    matomo.trackEvent(
      siteIDS.split(','),
      category,
      action,
      name,
      value,
      callback,
    );
  }
};
